import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import VideoPreview from "components/ui/base/VideoPreview";
import FullWidthImage from "components/ui/base/FullWidthImage";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import { IGatsbyImageData } from "gatsby-plugin-image";

interface TeamReportsPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: TeamReportsPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.team-reports.seo.title")}
        description={t("pages.features.team-reports.seo.description")}
        image={{
          relativePath: "meta/chat-team-reports.jpg",
          alt: "Chat team reports",
        }}
      />

      <PlainHeader
        title={t("pages.features.team-reports.title")}
        description={t("pages.features.team-reports.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={700}
        descriptionMaxWidth={500}
        image={{
          relativePath:
            "pages/features/team-reports/team-chat-reports-with-avg-replies.jpg",
          alt: "Team chat Reports with Average Replies",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Team Reports",
            path: "/features/team-reports/",
          },
        ]}
      />

      <VideoPreview
        id={"chat-reports"}
        title={t("pages.features.team-reports.1.title")}
        paragraph={t("pages.features.team-reports.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "pages/features/team-reports/live-chat-reports-new-conversations.mp4",
          },
        ]}
      />

      <FullWidthImage
        id={"agent-reports"}
        title={t("pages.features.team-reports.2.title")}
        description={t("pages.features.team-reports.2.subtitle")}
        image={{
          relativePath:
            "pages/features/team-reports/team-chat-reports-with-avg-replies.jpg",
          alt: t("pages.features.team-reports.2.title"),
        }}
      />

      <ImageWithDescription
        id={"help-center-reports"}
        title={t("pages.features.team-reports.3.title")}
        description={t("pages.features.team-reports.3.subtitle")}
        image={{
          relativePath:
            "pages/features/team-reports/help-center-article-reports.jpg",
          alt: t("pages.features.team-reports.3.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: "/features/help-center/",
          text: t("pages.features.team-reports.3.cta"),
        }}
      />

      <TestimonialWithImageFrancesca />

      <VideoPreview
        id={"failed-searches-reports"}
        title={t("pages.features.team-reports.4.title")}
        paragraph={t("pages.features.team-reports.4.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "pages/features/help-center/help-center-failed-searches.mp4",
          },
        ]}
        cta={{
          link: "/features/help-center/",
          text: t("pages.features.team-reports.4.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.features.team-reports.5.title")}
        description={t("pages.features.team-reports.5.subtitle")}
        image={{
          relativePath: "pages/features/team-reports/reports-csat.jpg",
          alt: t("pages.features.team-reports.5.title"),
          position: "left",
          fullWidth: true,
        }}
        cta={{
          link: "/features/surveys/",
          text: t("pages.features.team-reports.5.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.features.team-reports.6.title")}
        description={t("pages.features.team-reports.6.subtitle")}
        id={"screen-share"}
        image={{
          relativePath: "pages/features/team-reports/nps-votes-reports.jpg",
          alt: t("pages.features.team-reports.6.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: "/features/net-promoter-score/",
          text: t("pages.features.team-reports.6.cta"),
        }}
      />

      <FeatureGrid
        title={t("pages.features.team-reports.others.title")}
        centered={false}
        sections={[
          {
            title: t("pages.features.team-reports.others.helpCenter.title"),
            description: t(
              "pages.features.team-reports.others.helpCenter.description"
            ),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t("pages.features.team-reports.others.helpCenter.cta"),
                path: "/features/help-center/",
              },
            ],
          },
          {
            title: t("pages.features.team-reports.others.surveys.title"),
            description: t(
              "pages.features.team-reports.others.surveys.description"
            ),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t("pages.features.team-reports.others.surveys.cta"),
                path: "/features/surveys/",
              },
            ],
          },
          {
            title: t("pages.features.team-reports.others.funnels.title"),
            description: t(
              "pages.features.team-reports.others.funnels.description"
            ),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.features.team-reports.others.funnels.cta"),
                path: "/features/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const teamReportsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/chat-team-reports.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
